import {authorizedClientRequest} from "@/api/csr-request";
import {StatisticsDocument} from "@/shared/codegen/types";

async function fetchStatistics() {
  const result = await authorizedClientRequest({
    document: StatisticsDocument,
    variables: {},
  });
  if (result.statistics) return result.statistics.totalUsers;
  throw new Error("Couldn't retrieve information about statistics.");
}

export const HomeModule = {
  fetchStatistics,
};
