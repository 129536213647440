"use client";

import {TeamOutlined} from "@ant-design/icons";
import {useQuery} from "@tanstack/react-query";
import {Card, Col, Row, Statistic} from "antd";
import {useSession} from "next-auth/react";
import {useTranslations} from "next-intl";
import React, {useCallback, useMemo} from "react";

import PageHeader from "@/app/_components/page-header";
import {HomeModule} from "@/modules/home";

const Home = () => {
  const translation = useTranslations("home");
  const {data: session} = useSession();
  const baseQueryKey = "home";
  const queryKey = useMemo(() => [baseQueryKey], []);
  const fetchRecords = useCallback(() => {
    return HomeModule.fetchStatistics();
  }, []);
  const queryResult = useQuery({queryKey, queryFn: fetchRecords});
  const {data: dataRecords} = queryResult;

  return (
    <PageHeader title={translation("homeTitle")}>
      {session?.roles?.isAdministrator && (
        <Row justify="start" gutter={16}>
          <Col>
            <Card>
              <Statistic title={translation("homeTotalUsers")} value={dataRecords} prefix={<TeamOutlined />} />
            </Card>
          </Col>
        </Row>
      )}
    </PageHeader>
  );
};

export default Home;
