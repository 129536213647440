"use client";

import {Space, Typography} from "antd";
import React from "react";

type PageHeaderProps = {
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  tags?: React.ReactNode;
  center?: React.ReactNode;
  extra?: React.ReactNode;
  children?: React.ReactNode;
};

const PageHeader: React.FC<PageHeaderProps> = (props) => {
  const {title, subtitle, tags, center, extra, children} = props;

  return (
    <div style={{margin: 20}}>
      {(title || tags || center || extra) && (
        <div style={{display: "grid", gridTemplateColumns: "1fr auto 1fr", marginBottom: 16}}>
          <Space direction="vertical" size={0}>
            <Space>
              {typeof title === "string" && (
                <div>
                  <Typography.Title style={{margin: 0}} level={4}>
                    {title}
                  </Typography.Title>
                </div>
              )}
              {typeof title !== "string" ? title : null}

              {tags && <div>{tags}</div>}
            </Space>
            {typeof subtitle === "string" && (
              <div>
                <Typography.Text type="secondary" style={{margin: 0}}>
                  {subtitle}
                </Typography.Text>
              </div>
            )}
            {typeof subtitle !== "string" ? subtitle : null}
          </Space>
          {center && <div>{center}</div>}
          {extra && <div style={{whiteSpace: "nowrap", textAlign: "right"}}>{extra}</div>}
        </div>
      )}
      <div>{children}</div>
    </div>
  );
};

export default PageHeader;
